<template>
  <div class="service_content" contenteditable="true"
       style="outline: none; min-height: 779px; background-color: white; padding: 20px 15%; border-radius: 4px 4px 0px 0px; margin-left: 5px; line-height: 32px; letter-spacing: 1px; margin-top: 0px; font-family: akkurat; margin-bottom: 0px;" tabindex="0" spellcheck="false"
       role="textbox" aria-multiline="true" aria-describedby="cke_42">
    <h2 style="text-align: center;font-size: 30px">
      <strong><strong>秘塔科技隐私政策</strong></strong>
    </h2>
    <p><strong><strong>引言</strong></strong></p>
    <p>
      秘塔科技非常重视用户的个人信息和隐私保护。秘塔科技的使命之一是向专业人士提供科技服务和平台，并协助他们在相关工作上事半功倍，发挥所长。在履行这一使命时，我们致力于对您开诚布公，让您了解我们收集哪些关于您的数据、以及我们如何收集、使用、分享、储存和管理这些信息。</p>
    <p>1.重要提示</p>
    <p>1.1.我们深知个人信息对您的重要性。我们会严格按照相关法律法规及本秘塔科技隐私政策（下称“本隐私政策”）的规定收集、使用、储存、分享和管理您的个人信息。本隐私政策适用于我们向您提供的各项产品及服务。</p>
    <p>
      1.2.在使用我们的产品或服务前，请务必仔细阅读并充分理解本隐私政策。鉴于《秘塔科技用户协议》已经包含本隐私政策及其更新版本（秘塔科技会及时提示您更新的情况），您同意和接受《秘塔科技用户协议》即表明您已同意我们按照本隐私政策（含更新版本）收集、使用、分享、储存和管理您的相关信息。</p>
    <p>
      1.3.我们提供产品或服务的网络平台（包括但不限于数据库、官网、移动APP）可能包含跳转至其他网站的链接或指引。请注意，我们不控制这些被跳转所至的网站，本隐私政策在任何情况下均不适用于这些网站。我们建议您在访问这些网站时仔细阅读他们的隐私政策。</p>
    <p>1.4.如对本隐私政策内容有任何疑问、意见或建议，您可按照本隐私政策第13条显示的任一联系方式与我们联系。</p>
    <p>2.个人信息</p>
    <p>
      您的“个人信息”：是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括姓名、身份证件号码、通信通讯联系方式、住址、账号密码、财产状况、行踪轨迹、个人喜好。</p>
    <p>3.我们如何收集您的个人信息</p>
    <p>
      在您使用我们产品或服务的过程中，我们仅会在非常必要的情况下和非常有限的限度内收集您的个人信息，并且这些信息仅用于向您提供产品或服务、优化我们的产品或服务，以及保障您的账号安全。具体而言，我们将按照如下方式收集您主动提供或因为使用服务而产生的信息：</p>
    <p>3.1.在您进行注册认证、发布信息、互动交流或使用我们的服务时，基于法律要求或实现功能所必需，我们可能会收集您的姓名、联络方式、头像、地理位置等个人信息。</p>
    <p>
      3.2．若您通过第三方账号（如微信、QQ等）登录进入平台，您此时将授权我们获取您在第三方平台注册的公开信息（头像、昵称等），并在您同意本隐私政策后将您的第三方账号与您在平台的账号绑定，使您可以通过第三方账号直接登录并使用我们的服务。</p>
    <p>3.3.若我们的服务涉及为您提供个性化的信息或服务，我们可能会收集您关注、收藏、搜索、浏览偏好（您感兴趣的文章等）等您的操作、使用行为信息；以及您反馈、发布、点赞、评论等您主动提供的信息；</p>
    <p>3.4.当您使用我们的服务时，我们可能会收集您所使用的相关设备以及网络环境等信息，以便为您提供持续稳定的网络运营支持，同时有助于我们不断改善自身或服务，从而使您在使用我们的服务过程中获得最优体验。</p>
    <p>3.5.若您在线购买我们的服务，根据需要您可提供姓名、通信地址、联系方式、银行账号以及其他财务信息。</p>
    <p>
      3.6.请您了解，随着我们业务的发展，可能会对我们的平台和服务有所调整变化。原则上，当新功能或服务与个性化推荐、发布信息、互动交流、搜索查询、注册认证等场景相关时，收集与使用的个人信息将与原处理目的具有直接或合理关联。在与原处理目的无直接或合理关联的场景下，我们收集、使用您的个人信息，会再次进行告知，并征得您的同意。</p>
    <p>3.7.您充分知晓，在以下情形下，我们收集、使用个人信息无需征得您的授权同意：</p>
    <p>3.7.1.与国家安全、国防安全有关的；</p>
    <p>3.7.2.与公共安全、公共卫生、重大公共利益有关的；</p>
    <p>3.7.3.与犯罪侦查、起诉、审判和判决执行等有关的；</p>
    <p>3.7.4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>3.7.5.所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
    <p>3.7.6.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
    <p>3.7.7.根据您的要求签订合同所必需的；</p>
    <p>3.7.8.用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；</p>
    <p>3.7.9.法律法规规定的其他情形。</p>
    <p>4.第三方向您收集的个人信息</p>
    <p>
      我们可能会与第三方服务提供商合作，在服务中接入第三方服务（例如投放广告、链接或者其它形式的推广内容）。我们不会将您提供给我们或者我们收集的您的个人信息（如手机号码、昵称、头像、好友列表等）与第三方服务商共享，也不会代表第三方服务商为其收集您的个人信息。通过我们的服务接入的第三方服务商可能有自己的隐私政策，其不受本隐私政策的约束。第三方服务商可能会根据其自己的隐私政策收集为您提供服务所需的个人信息。我们将尽商业上合理的努力要求第三方服务商在处理您的个人信息时遵守相关法律法规，尽力要求其采取相关的保密和安全措施。</p>
    <p>5.我们如何使用您的个人信息</p>
    <p>在收集您的个人信息后，我们将根据如下规则使用您的个人信息：</p>
    <p>5.1.我们会根据本隐私政策的约定并为实现我们的服务功能对所收集的个人信息进行使用。</p>
    <p>5.2.请您注意，对于您在使用我们的服务时所提供的所有个人信息，除非您删除或通过相关设置拒绝我们收集，否则您将在使用服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息。</p>
    <p>5.3.我们会对旗下服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的服务的整体使用趋势。但这些统计信息不会包含您的任何身份识别信息。</p>
    <p>5.4.当我们要将您的个人信息用于本政策未载明的其它用途时，或将基于特定目的收集而来的信息用于其他目的时，我们会主动事先征求您的明示同意。</p>
    <p>6.我们如何使用Cookie和同类技术</p>
    <p>
      为了更好地向您提供我们的服务，我们可能会使用了自动数据收集工具。借助这些技术，为您提供流畅、优质、个性化的用户体验。我们不会将上述技术用于本政策所述目的之外的任何用途。请您理解，我们的某些服务只能通过使用上述技术才可得到实现。如您的设备或相关程序允许，您可以通过改变设置，或通过访问提供商的网页，来关闭或删除上述技术。但这一举动可能会影响您使用我们的服务。</p>
    <p>7.我们如何共享、转让、公开披露您的个人信息</p>
    <p><em><u><u><em>共享</em></u></u></em></p>
    <p>7.1.我们将严格遵守相关法律法规，对您的个人信息予以保密。除以下情况外，我们不会向其他人共享您的个人信息：</p>
    <p>7.1.1.事先获得您明确的同意或授权；</p>
    <p>7.1.2.根据适用的法律法规规定，或基于司法或行政主管部门的强制性要求进行提供；</p>
    <p>7.1.3.在法律法规允许的范围内，为维护您或其他用户或其他个人的生命、财产等合法权益或是社会公共利益而有必要提供；</p>
    <p>7.1.4.应您的监护人的合法要求而提供您的信息；</p>
    <p>7.1.5.根据与您签署的相关协议或其他的法律文件约定而提供；</p>
    <p>7.1.6.与本隐私政策第4章所述第三方进行共享：您在使用我们的服务的过程中使用本隐私政策第4章所述的第三方服务时，我们与第三方的共享规则将遵循本隐私政策第4章项下相关约定；</p>
    <p>7.1.7.我们可能会基于您的相应授权将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策所述目的之约束。我们的关联方如要改变个人信息的处理目的，将适时向您征得明示同意。</p>
    <p><em><u><u><em>转让</em></u></u></em></p>
    <p>7.2.我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
    <p>7.2.1.事先得您明确的同意或授权；</p>
    <p>7.2.2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求而必须进行提供；</p>
    <p>7.2.3.根据与您签署的相关协议或其他的法律文件约定而提供；</p>
    <p>
      7.2.4.在涉及收购、兼并、破产清算、重组等变更时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司或组织继续履行本隐私政策项下的责任和义务。如变更后的主体需变更个人信息使用目的，我们会要求其事先获得您的明示同意。</p>
    <p><em><u><u><em>公开披露</em></u></u></em></p>
    <p>7.3.我们仅会在以下情况下，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</p>
    <p>7.3.1根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
    <p>
      7.3.2根据法律、法规的要求、行政或司法机关的强制性要求，我们可能会公开披露您的个人信息。当我们收到上述披露请求时，我们会依法要求请求方出具相关法律文件，如传票或调查函等。我们会慎重审查每一披露请求，以确保该等披露请求符合相关法律规定。在法律法规许可的前提下，我们会对包含披露信息的文件进行加密保护。</p>
    <p>8.我们如何保护您的个人信息</p>
    <p>8.1.我们非常重视个人信息安全，并会采取一切合理可行的措施，持续保护您的个人信息，以防其他人在未经授权的情况下访问、篡改或披露我们收集的您的个人信息。</p>
    <p>8.2.我们会采取一切合理可行的措施，确保未收集无关的信息。</p>
    <p>
      8.3.请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</p>
    <p>9.我们如何保存您的个人信息</p>
    <p>9.1.在用户使用我们服务期间，我们会持续保存用户的个人信息。我们承诺将是为了实现我们产品及/或服务目的所必需的最短期限。在超出上述期限后，我们会对您的相关信息进行删除或匿名化处理。</p>
    <p>9.2.您的个人信息将全部被存储于中华人民共和国境内。目前我们不存在向境外提供个人信息的场景。如将来涉及向境外传输个人信息，我们将明确向您告知个人信息出境的目的、接收方、安全保障措施等情况，并另行征得您的同意。</p>
    <p>10.如何管理您的个人信息</p>
    <p>10.1.您可以通过登录您在我们网站开立的账号来查看您提交给我们的个人信息。</p>
    <p>10.2.为保证您正确身份验证，请及时更新相关信息，以确保它们是准确、最新且完整的，在此过程中如有任何问题，请随时与我们联系。</p>
    <p>10.3.在您注销账号或您的账号到期后，如果您提出申请，我们将根据您的意愿和法律规定将您的个人信息删除或进行匿名化处理。</p>
    <p>11.未成年人隐私保护</p>
    <p>11.1.如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</p>
    <p>11.2.对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享或披露信息。</p>
    <p>12.隐私政策的修订和通知</p>
    <p>
      由于服务内容、联络方式、法律和监管政策要求等变化，我们可能会适时对本隐私政策进行修订。当隐私政策发生变更时，我们会在我们的平台发布或向您提供的电子邮箱地址发送更新后的版本。为了您能及时接收到通知，建议您在电子邮箱地址变动时及时更新账号信息或通知我们。</p>
    <p>13.联系我们</p>
    <p>在本隐私政策提及的所有可能需要与我们取得联系的情况下，您可以通过以下方式联系我们：</p>
    <p>联系电话：15901025282</p>
    <p>电子邮件：support@metasota.ai</p>
  </div>
</template>

<script>
export default {
  name: "privacyPolicy",
  created() {
    document.title = '秘塔科技隐私政策';
  }
}
</script>

<style scoped>
  .service_content {
    text-align: left;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: #2c3e50
  }
</style>
